import Element from "../domain/Element/Element";
import {EElementType} from "../domain/Element/EElementType";
import {EMAIL_PATTERN, NUMBER_PATTERN} from "./ValidationPattern";
import ValidationItem from "./ValidationItem";
import {isValidBIC, isValidIBAN} from 'ibantools';

import {isValidPhoneNumber} from 'react-phone-number-input'
import envVariable from '../presentation/util/envVariable';

export default class ValidationSchema {

    create(element: Element, isRequired: boolean): Record<string, unknown> {

        const validationSchema = {};

        const attributes = element.attributes;

        if (attributes?.required || isRequired) {
            if (attributes?.errorMessage !== undefined && attributes?.errorMessage != null) {
                validationSchema['required'] = attributes.errorMessage;
            } else {
                validationSchema['required'] = `Veuillez saisir le champ ${attributes?.label}`
            }
        }

        if (element.type == EElementType.INPUT_TEL) {
            validationSchema['validate'] = (value: string) => {
                if (value === undefined) {
                    return `Veuillez saisir le champ ${attributes?.label}`
                }
                if (!isValidPhoneNumber(value)) {
                    return 'Format du téléphone invalide'
                }
                return true
            }
        }

        if (element.type == EElementType.INPUT_MAIL) {
            validationSchema['pattern'] = new ValidationItem("Email invalide", EMAIL_PATTERN);
        }

        if (element.type == EElementType.INPUT_IBAN || element.type == EElementType.INPUT_IBAN_CODE) {
            validationSchema['validate'] = (ibanToTest: string) => isValidIBAN(ibanToTest) || 'Format de l\'IBAN invalide'
        }

        if (element.type == EElementType.INPUT_BIC_CODE) {
            validationSchema['validate'] = (bicToTest: string) => isValidBIC(bicToTest) || 'Format du BIC invalide'
        }

        if (element.type == EElementType.INPUT_FILE_WITH_OPTIONS) {
            if (attributes?.required || isRequired) {
                if (attributes?.errorMessage === undefined || attributes?.errorMessage == null) {
                    validationSchema['required'] = `Veuillez téléverser votre ${attributes?.label ?? 'pièce justificative'}`
                }
            }
        }
        
        if (element.id === '06fccd7c-9279-11eb-a8b3-0242ac130003'
            || element.id === '06fccf66-9279-11eb-a8b3-0242ac130003'
            || element.id === '06fcd02e-9279-11eb-a8b3-0242ac130003'
            || element.id === '06fcd0ec-9279-11eb-a8b3-0242ac130003'
            || element.id === '06fcca8e-9279-11eb-a8b3-0242ac130003'
            || element.id === '06fccb42-9279-11eb-a8b3-0242ac130003') {
            validationSchema['validate'] = (value: string) => value === 'Oui' || `Votre souscription ne peut être enregistrée et nous vous invitons à vous rapprocher de la Société de Gestion (Tél : 03.80.59.90.91) (e-mail : ${envVariable('REACT_APP_EMAIL_CONTACT')}) ou, le cas échéant, de votre Conseiller financier, afin de compléter votre connaissance des risques associés aux investissements en parts de SCPI.`
        }

        if (element.id === '06fcac3e-9279-11eb-a8b3-0242ac130003') {
            validationSchema['validate'] = (value: string) => value === 'Non' || (element?.attributes?.errorMessage || "")
        }

        // SIRET
        if (element.id === '34cd9b20-6ec1-421d-9879-27353ad71606') {
            validationSchema['pattern'] = new ValidationItem("SIRET invalide", NUMBER_PATTERN);
        }

        if (element.type == EElementType.INPUT_INTEGER || element.type == EElementType.INPUT_PRODUCT_SHARES_NUMBER) {
            if (element.attributes?.min !== null || element.attributes?.max !== null) {
                validationSchema['validate'] = (value: string) => {
                    if(value === undefined) {
                        if (attributes?.errorMessage !== undefined && attributes?.errorMessage != null) {
                            return attributes.errorMessage;
                        } else {
                            return `Veuillez saisir le champ ${attributes?.label}`
                        }            
                    } else {
                        if (element.attributes?.min !== null && Number(element.attributes.min) > Number(value)) {
                            if (attributes?.errorMessage !== undefined && attributes?.errorMessage != null) {
                                return attributes.errorMessage;
                            } else {
                                return `Veuillez saisir une valeur supérieure à ${element.attributes.min}`
                            }            
                        }
                        if (element.attributes?.max !== null && Number(element.attributes.max) < Number(value)) {
                            if (attributes?.errorMessage !== undefined && attributes?.errorMessage != null) {
                                return attributes.errorMessage;
                            } else {
                                return `Veuillez saisir une valeur inférieure ou égale à ${element.attributes.max}`
                            }            
                        }
                        return true
                    }
                }    
            }
        }

        if (element.type == EElementType.INPUT_FLOAT || element.type == EElementType.INPUT_FLOAT_CURRENCY_AMOUNT) {
            if (element.attributes?.min !== null || element.attributes?.max !== null) {
                validationSchema['validate'] = (value: string) => {
                    if(value === undefined) {
                        if (attributes?.errorMessage !== undefined && attributes?.errorMessage != null) {
                            return attributes.errorMessage;
                        } else {
                            return `Veuillez saisir le champ ${attributes?.label}`
                        }            
                    } else {
                        if (element.attributes?.min !== null && Number(element.attributes.min) > Number(value)) {
                            if (attributes?.errorMessage !== undefined && attributes?.errorMessage != null) {
                                return attributes.errorMessage;
                            } else {
                                return `Veuillez saisir une valeur supérieure à ${element.attributes.min}`
                            }            
                        }
                        if (element.attributes?.max !== null && Number(element.attributes.max) < Number(value)) {
                            if (attributes?.errorMessage !== undefined && attributes?.errorMessage != null) {
                                return attributes.errorMessage;
                            } else {
                                return `Veuillez saisir une valeur inférieure ou égale à ${element.attributes.max}`
                            }            
                        }
                        return true
                    }
                }    
            }
        }

        if(element.type == EElementType.SELECT && (attributes?.required || isRequired)) {
            validationSchema['validate'] = (value: string) => {
                if(value === undefined || value?.length <= 0) {
                    if (attributes?.errorMessage !== undefined && attributes?.errorMessage != null) {
                        return attributes.errorMessage;
                    } else {
                        if(attributes.multiple)
                            return `Veuillez sélectionner au moins un champ`
                        return `Veuillez sélectionner un champ`
                    }
                }
                return true
            }
        }

        return validationSchema

    }
}