/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from 'react';
import {FC, useMemo, useState} from 'react';
import {FieldError} from 'react-hook-form';
import {useAppSelector} from '../../../store/hook';

import Element from '../../../domain/Element/Element'
import {EElementType} from '../../../domain/Element/EElementType';
import ValidateConditionUseCase from '../../../domain/Condition/UseCase/ValidateConditionUseCase';
import ValidationSchema from '../../../validation/ValidationSchema';
import ERadioType from './radios/ERadioType';
import FormGateway from '../../../gateway/Form/FormGateway';
import Input from './input/Input';
import RadiosList from './radios/RadiosList';
import MagicCheckbox from './checkbox/MagicCheckbox';
import Button from '../button/Button';
import Checkbox from './checkbox/Checkbox';
import Text from './text/Text'
import IconFactory from '../svg/IconFactory';
import RangeSlider from './range-slider/RangeSlider';
import DatePickerSimple from './datepicker/DatePickerSimple';
import DataList from './select/DataList';
import MyUploaderWithOptions from './dropzone/MyUploaderWithOptions';
import Sort from './sort/Sort';
import ArrayChoice from './array-choice/ArrayChoice';
import {isDisabledCondition, isRequiredCondition, isVisibleCondition} from '../../util/validateCondition';
import Calculate from './Calculate/Calculate';
import InputConvertNumberToWord from './converter/InputConvertNumberToWord';
import Presentation from './presentation/Presentation';
import SelectCustom from './select/SelectCustom';
import DataListChoice from './select/DataListChoice';
import ButtonPrint from '../button/ButtonPrint';
import Download from '../svg/Download';
import Phone from './phone/Phone';
import ButtonCreateProspect from './prospect/ButtonCreateProspect';
import PercentageInputInteger from "./percentage/PercentageInputInteger";
import CurrencyAmountInput from "./input/CurrencyAmountInput";
import BICCodeInput from "./input/BICCodeInput";
import IBANCodeInput from "./input/IBANCodeInput";
import OrderedSelectGroup from "./order/OrderedSelectGroup";
import PercentageInputRangeGroup from "./percentage/PercentageInputRangeGroup";
import PercentageInputRange from "./percentage/PercentageInputRange";
import DocusignIframeButton from "./docusign/DocusignIframeButton";
import ScoringTextInput from './input/ScoringTextInput';

interface IProps {
    blockId: string
    element: Element
    register: any
    clearErrors: any
    control: any
    error: FieldError
    formData: Record<string, unknown> | []
    childElement: Element | undefined
    childrenElements: (Element | undefined)[] | undefined
}

/**
 * Reçoit un élément flexible en entrée et retourne l'élément concerné en fonction du type
 * @param blockId
 * @param element
 * @param register
 * @param error
 * @param clearErrors
 * @param control
 * @param formData
 * @param childrenElements
 * @constructor
 */
const ElementFactory: FC<IProps> = (
    {
        blockId,
        element,
        register,
        error,
        clearErrors,
        control,
        formData,
        childElement,
        childrenElements
    }
) => {
    const [timestamp, setTimestamp] = useState<number | null>(null)
    const [validationState, setValidationState] = useState<boolean>(false)

    const elementsFromStoreString = useAppSelector(state => state.elements)
    const elementsFromStore = JSON.parse(elementsFromStoreString.currentElements)
    const dossierState = localStorage.getItem('dossier_state') ?? '';
    const readOnlyMode = 'back_office_user' == localStorage.getItem('role');

    if (
        undefined != element.condition
        || (undefined != element.calculate  && '' !== element.calculate)
        || (undefined != element.reference  && '' !== element.reference)
    ) {
        const currentIndexForElementId = elementsFromStore.findIndex(
            (storedElement) => storedElement.id === element.id
        )
        if (timestamp !== elementsFromStore[currentIndexForElementId].timestamp) {
            setTimestamp(elementsFromStore[currentIndexForElementId].timestamp)
        }
    }

    useMemo(
        () => {
            if (undefined != element.condition) {
                const validateConditionUseCase = new ValidateConditionUseCase(new FormGateway())
                setValidationState(validateConditionUseCase.execute(element.condition))
            }
        },
        [timestamp, element.condition]
    )

    if (isDisabledCondition(element, validationState)) {
        return null
    }

    if (!isVisibleCondition(element, validationState)) {
        return null
    }

    const attributes = element.attributes
    const isRequired = isRequiredCondition(element, validationState) && false === readOnlyMode
    const schema = false === readOnlyMode ? (new ValidationSchema().create(element, isRequired)) : {}
    const isDefaultValueBoolean = (attributes?.defaultValue === 'true')
    const defaultValue = (formData && formData[element.id] && formData[element.id] !== '') ? String(formData[element.id]) : attributes?.defaultValue

    const childOfChildrenElements: Element[] | undefined = childrenElements?.map(
        (childElement) => (
            elementsFromStore.find(
                (element) => element.id === childElement?.attributes?.childElementId
            )
        )
    )

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    function dateIsValid(dateStr) {
        const regex = /^\d{2}\/\d{2}\/\d{4}$/;
        if (dateStr.match(regex) === null) {
            return false;
        }
        const [day, month, year] = dateStr.split('/');
        const isoFormattedStr = `${year}-${month}-${day}`;
        const date = new Date(isoFormattedStr);
        const timestamp = date.getTime();
        if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
            return false;
        }
        return date.toISOString().startsWith(isoFormattedStr);
    }

    const formatDate = (dateString) => {
        if (dateIsValid(dateString)) {
            return dateString;
        }
        const dateObject = (dateString === undefined || dateString === null)
            ? new Date()
            : new Date(dateString?.substr(0, 10));
        return [
            padTo2Digits(dateObject.getDate()),
            padTo2Digits(dateObject.getMonth() + 1),
            dateObject.getFullYear(),
        ].join('/');
    }

    element.attributes.required = isRequired;

    if (undefined != element.calculate && '' !== element.calculate) {
        return <Calculate
            element={element}
            formData={formData}
            register={register(schema)}
            clearErrors={clearErrors}
            readonly={readOnlyMode || attributes?.readonly}
        />
    }

    switch (element.type) {

        case EElementType.OUTPUT_SCORING_RESULT:
            return null // Keep it invisible without any condition
        case EElementType.OUTPUT_TEXT:
            return <Text
                classes={element.cssClasses}
                content={attributes?.label}
            />
        case EElementType.INPUT_TEXT:
            return <Input
                classes={element.cssClasses}
                label={attributes?.label}
                register={register(schema)}
                clearErrors={clearErrors}
                type={'text'}
                error={error}
                id={element.id}
                name={element.name}
                placeholder={attributes?.label}
                required={isRequired}
                readonly={readOnlyMode || attributes?.readonly}
                help={attributes?.help}
                defaultValue={defaultValue}
                isUppercase={attributes?.uppercase}
            />
        case EElementType.INPUT_TEL:
            return <Phone
                classes={element.cssClasses}
                label={attributes?.label}
                register={register(schema)}
                control={control}
                schema={schema}
                clearErrors={clearErrors}
                type={'tel'}
                error={error}
                id={element.id}
                name={element.name}
                placeholder={attributes?.label}
                required={isRequired}
                defaultValue={defaultValue}
                help={attributes?.help}
                readonly={readOnlyMode || attributes?.readonly}
            />
        case EElementType.INPUT_MAIL:
            return <Input
                classes={element.cssClasses}
                label={attributes?.label}
                register={register(schema)}
                clearErrors={clearErrors}
                type={'email'}
                error={error}
                readonly={readOnlyMode || attributes?.readonly}
                id={element.id}
                name={element.name}
                placeholder={attributes?.label}
                required={isRequired}
                defaultValue={defaultValue}
                help={attributes?.help}
                isUppercase={attributes?.uppercase}
            />
        case EElementType.INPUT_IBAN:
            return <Input
                classes={element.cssClasses}
                label={attributes?.label}
                register={register(schema)}
                clearErrors={clearErrors}
                type={'text'}
                error={error}
                id={element.id}
                name={element.name}
                placeholder={attributes?.label}
                required={isRequired}
                readonly={readOnlyMode || attributes?.readonly}
                defaultValue={defaultValue}
                help={attributes?.help}
                isUppercase={attributes?.uppercase}
            />
        case EElementType.INPUT_IBAN_CODE:
            return <IBANCodeInput
                classes={element.cssClasses}
                label={attributes?.label}
                register={register(schema)}
                clearErrors={clearErrors}
                error={error}
                id={element.id}
                name={element.name}
                placeholder={attributes?.label}
                required={isRequired}
                readonly={readOnlyMode || attributes?.readonly}
                defaultValue={defaultValue}
                help={attributes?.help}
            />
        case EElementType.INPUT_BIC_CODE:
            return <BICCodeInput
                classes={element.cssClasses}
                label={attributes?.label}
                register={register(schema)}
                clearErrors={clearErrors}
                error={error}
                id={element.id}
                name={element.name}
                placeholder={attributes?.label}
                required={isRequired}
                readonly={readOnlyMode || attributes?.readonly}
                defaultValue={defaultValue}
                help={attributes?.help}
            />
        case EElementType.INPUT_DATE:
        case EElementType.INPUT_BIRTHDATE:
            return <DatePickerSimple
                classes={element.cssClasses}
                label={attributes?.label}
                id={element.id}
                name={element.name}
                minDateString={attributes?.minDate?.date}
                maxDateString={attributes?.maxDate?.date}
                error={error}
                register={register(schema)}
                defaultValue={defaultValue ? formatDate(defaultValue) : defaultValue}
                help={attributes?.help}
                readonly={readOnlyMode || attributes?.readonly}
            />
        case EElementType.INPUT_PRODUCT_SHARES_NUMBER:
        case EElementType.INPUT_INTEGER:
            return <Input
                classes={element.cssClasses}
                label={attributes?.label}
                register={register(schema)}
                clearErrors={clearErrors}
                type={'number'}
                pattern='[0-9]*'
                min={attributes?.min}
                max={attributes?.max}
                error={error}
                id={element.id}
                name={element.name}
                placeholder={attributes?.label}
                required={isRequired}
                readonly={readOnlyMode || attributes?.readonly}
                help={attributes?.help}
                defaultValue={(formData && formData[element.id]) ? Number(formData[element.id]) : attributes?.defaultValue}
            />
        case EElementType.INPUT_RANGE:
            return <RangeSlider
                id={element.id}
                min={attributes?.min}
                max={attributes?.max}
                start={defaultValue ? Number(defaultValue) : attributes?.start}
                error={error}
                label={attributes?.label}
                classes={element.cssClasses}
                name={element.name}
                register={register(schema)}
                initialValue={Number(defaultValue)}
                readonly={readOnlyMode}
            />
        case EElementType.INPUT_CONVERT_NUMBER_TO_WORD:
            return <InputConvertNumberToWord
                classes={element.cssClasses}
                label={attributes?.label}
                register={register}
                type={'text'}
                error={error}
                help={attributes?.help}
                id={element.id}
                name={element.name}
                placeholder={attributes?.label}
                required={isRequired}
                reference={element.reference ?? ''}
                readonly={readOnlyMode || attributes?.readonly}
                timestamp={timestamp}
                element={element}
                defaultValue={defaultValue}
            />
        case EElementType.INPUT_PERCENTAGE_INTEGER:
            return <PercentageInputInteger
                element={element}
                formData={formData}
                register={register(schema)}
                clearErrors={clearErrors}
                error={error}
                readonly={readOnlyMode}
            />
        case EElementType.INPUT_PERCENTAGE_RANGE:
            return <PercentageInputRange
                element={element}
                formData={formData}
                error={error}
                register={register(schema)}
                readonly={readOnlyMode}
            />
        case EElementType.GROUP_PERCENTAGE_INPUT_RANGE:
            return <PercentageInputRangeGroup
                element={element}
                percentageElements={childrenElements}
                formData={formData}
                register={register(schema)}
                clearErrors={clearErrors}
                error={error}
                defaultValue={(formData && formData[element.id]) ? Number(formData[element.id]) : attributes?.defaultValue}
                readonly={readOnlyMode}
            />
        case EElementType.INPUT_ORDERED_INTEGER:
            return null
        case EElementType.GROUP_ORDERED_INTEGERS:
            return <OrderedSelectGroup
                childrenElements={childrenElements}
                formData={formData}
                classes={element.cssClasses}
                label={attributes?.label}
                register={register(schema)}
                clearErrors={clearErrors}
                type={'text'}
                error={error}
                id={element.id}
                control={control}
                name={element.name}
                placeholder={attributes?.label}
                required={isRequired}
                readonly={readOnlyMode || attributes?.readonly}
                help={attributes?.help}
                defaultValue={(formData && formData[element.id]) ? Number(formData[element.id]) : attributes?.defaultValue}
            />
        case EElementType.INPUT_FLOAT:
            return <Input
                classes={element.cssClasses}
                label={attributes?.label}
                register={register(schema)}
                clearErrors={clearErrors}
                type={'number'}
                step={0.01}
                min={attributes?.min}
                max={attributes?.max}
                error={error}
                id={element.id}
                name={element.name}
                placeholder={attributes?.label}
                required={isRequired}
                readonly={readOnlyMode || attributes?.readonly}
                help={attributes?.help}
                defaultValue={(formData && formData[element.id]) ? Number(formData[element.id]) : attributes?.defaultValue}
            />
        case EElementType.INPUT_FLOAT_CURRENCY_AMOUNT:
            return <CurrencyAmountInput
                classes={element.cssClasses}
                label={attributes?.label}
                register={register(schema)}
                clearErrors={clearErrors}
                type={'number'}
                step={0.01}
                min={attributes?.min}
                max={attributes?.max}
                error={error}
                id={element.id}
                name={element.name}
                placeholder={attributes?.label}
                required={isRequired}
                readonly={readOnlyMode || attributes?.readonly}
                help={attributes?.help}
                defaultValue={(formData && formData[element.id]) ? Number(formData[element.id]) : attributes?.defaultValue}
            />
        case EElementType.INPUT_CHECKBOX:
            return <Checkbox
                id={element.id}
                label={attributes?.label}
                classes={element.cssClasses}
                name={element.name}
                error={error}
                register={register(schema)}
                defaultChecked={(formData && typeof formData[element.id] !== 'undefined') ? ('true' === formData[element.id] || ('boolean' === typeof formData[element.id] && formData[element.id])) : isDefaultValueBoolean}
                help={attributes?.help}
                readonly={readOnlyMode || attributes?.readonly}
            />
        case EElementType.INPUT_RADIO:
            return <RadiosList
                options={attributes?.options}
                id={element.id}
                type={ERadioType.CIRCLE}
                name={element.name} error={error}
                classes={element.cssClasses}
                register={register(schema)}
                label={attributes?.label}
                defaultValue={defaultValue}
                help={attributes?.help}
                readonly={readOnlyMode || attributes?.readonly}
            />
        case EElementType.INPUT_RADIO_SIMPLE:
            return <RadiosList
                options={attributes?.options}
                id={element.id}
                type={ERadioType.SIMPLE}
                name={element.name} error={error}
                classes={element.cssClasses}
                register={register(schema)}
                label={attributes?.label}
                defaultValue={defaultValue}
                help={attributes?.help}
                readonly={readOnlyMode || attributes?.readonly}
            />
        case EElementType.TOGGLE:
            return <MagicCheckbox
                name={element.name}
                id={element.id}
                register={register(schema)}
                classes={element.cssClasses}
                bold={attributes?.bold}
                error={error}
                label={attributes?.label}
                checked={(formData && typeof formData[element.id] !== 'undefined') ? ('true' === formData[element.id] || ('boolean' === typeof formData[element.id] && formData[element.id])) : isDefaultValueBoolean}
                help={attributes?.help}
                readonly={readOnlyMode || attributes?.readonly}
            />
        case EElementType.CHOICE:
            return <RadiosList
                options={attributes?.options}
                id={element.id}
                type={ERadioType.RECTANGLE}
                name={element.name} error={error}
                classes={element.cssClasses}
                register={register(schema)}
                label={attributes?.label}
                defaultValue={defaultValue}
                help={attributes?.help}
                readonly={readOnlyMode || attributes?.readonly}
            />
        case EElementType.CHOICE_AUTOCOMPLETE:
            return <DataList
                options={attributes?.options}
                id={element.id}
                name={element.name}
                classes={element.cssClasses}
                label={attributes?.label} register={register(schema)}
                defaultValue={formData[element.id]}
                error={error}
                help={attributes?.help}
                readonly={readOnlyMode || attributes?.readonly}
            />
        case EElementType.ARRAY_CHOICE:
            return <ArrayChoice
                classes={element.cssClasses}
                options={attributes?.options}
                label={attributes?.label}
                id={element.id}
                name={element.name}
                register={register(schema)}
                defaultValue={formData[element.id]}
                help={attributes?.help}
                readonly={readOnlyMode || attributes?.readonly}
                variant={attributes?.variant}
            />
        case EElementType.DATALIST_CHOICE:
            return <DataListChoice
                options={attributes?.options}
                id={element.id}
                datalistId={(attributes?.datalistId) ?? ''}
                name={element.name}
                classes={element.cssClasses}
                label={attributes?.label} register={register(schema)}
                defaultValue={formData[element.id]}
                error={error}
                help={attributes?.help}
                readonly={readOnlyMode}
            />
        case EElementType.SELECT:
            return <SelectCustom
                options={attributes?.options}
                id={element.id}
                name={element.name}
                classes={element.cssClasses}
                register={register(schema)}
                schema={schema}
                required={isRequired}
                label={attributes?.label}
                multiple={attributes?.multiple}
                control={control}
                defaultValue={(formData && formData[element.id]) ? formData[element.id] : attributes?.defaultValue}
                help={attributes?.help}
                readonly={readOnlyMode || attributes?.readonly}
                error={error}
            />
        case EElementType.SELECT_ORDERED_INTEGER:
            return null
        case EElementType.INPUT_FILE_WITH_OPTIONS:
            return <MyUploaderWithOptions
                classes={element.cssClasses}
                label={attributes?.label}
                help={attributes?.help}
                options={attributes?.types}
                id={element.id}
                register={register(schema)}
                clearErrors={clearErrors}
                blockId={blockId}
                error={error}
                readonly={readOnlyMode || attributes?.readonly}
            />
        case EElementType.INPUT_SORT:
            return <Sort
                classes={element.cssClasses}
                options={attributes?.options}
                label={attributes?.label}
                id={element.id}
                name={element.name}
                register={register(schema)}
                defaultValue={formData[element.name]}
                help={attributes?.help}
                readonly={readOnlyMode || attributes?.readonly}
            />
        case EElementType.PRESENTATION:
            return <Presentation
                element={element}
            />
        case EElementType.BUTTON:
            return <Button
                classes={element.cssClasses}
                label={attributes?.label}
                type={attributes?.action}
                position={attributes?.icon?.position}
                icon={<IconFactory type={attributes?.icon?.type}/>}
                disabled={readOnlyMode}
            />
        case EElementType.BUTTON_PRINT:
            return <ButtonPrint
                classes={element.cssClasses}
                defaultValue={attributes?.defaultValue}
                label={attributes?.label}
                position="right"
                icon={<Download color="#ffffff"/>}
            />
        case EElementType.BUTTON_DOCUSIGN_IFRAME:
            return <DocusignIframeButton
                id={element.id}
                docusignId={attributes?.docusignId}
                label={attributes?.label}
                dossierState={dossierState}
            />
        case EElementType.BUTTON_CREATE_PROSPECT:
            return <ButtonCreateProspect/>
        case EElementType.OUTPUT_SCORING_TEXT:
            return <ScoringTextInput
                classes={element.cssClasses}
                label={attributes?.label}
                type={'text'}
                name={element.name}
                id={element.id}
                placeholder={''}
                help={attributes?.help}
                readonly={attributes?.readonly}
                defaultValue={defaultValue}
            />
        default:
            return <Input
                classes={element.cssClasses}
                label={attributes?.label}
                register={register(schema)}
                clearErrors={clearErrors}
                type={'text'}
                error={error}
                name={element.name}
                id={element.id}
                placeholder={attributes?.label}
                required={isRequired}
                defaultValue={defaultValue}
                help={attributes?.help}
                readonly={readOnlyMode || attributes?.readonly}
            />
    }
};

export default ElementFactory;
